<!-- table-inner -->
<template>
  <el-date-picker
    v-model="localDate"
    v-bind="$attrs"
    :type="localType"
    :value-format="valueFormat"
    range-separator="至"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder"
    :default-time="localDefaultTime"
    :placeholder="placeholder"
    :picker-options="localPickerOptions"
    @change="change"
  />
</template>
<script>
export default {
  name: 'IcsSearchDate',
  components: {
  },
  props: {
    date: {
      type: [String, Array],
      default: null
    },
    type: {
      type: String,
      default: 'daterange'
    },
    valueFormat: {
      type: String,
      default: 'yyyy-MM-dd HH:mm:ss'
    },
    startPlaceholder: {
      type: String,
      default: '开始日期'
    },
    endPlaceholder: {
      type: String,
      default: '结束日期'
    },
    defaultTime: {
      type: Array,
      default () {
        return ['00:00:00', '23:59:59']
      }
    },
    placeholder: {
      type: String,
      default: '选择日期'
    },
    isShowOptions: {
      type: Boolean,
      default: true
    },
    pickerOptions: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      localDate: [],
      allType: ['daterange', 'date'],
      localDefaultTime: null,
      options: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      localPickerOptions: {}
    }
  },
  computed: {
    localType: {
      get () {
        return this.allType.includes(this.type) ? this.type : 'daterange'
      }
    }
  },
  watch: {
    date: {
      handler (val) {
        this.localDate = val
      },
      immediate: true
    },
    localType: {
      handler (val) {
        if (val === 'daterange') {
          if (this.isShowOptions) {
            this.localPickerOptions = !this._.isEmpty(this.pickerOptions) ? this.pickerOptions : this.options
          }
          this.localDefaultTime = this.defaultTime
        } else {
          this.localDefaultTime = null
        }
      },
      immediate: true
    }
  },
  created () {
  },
  methods: {
    change (val) {
      this.$emit('update:date', val)
    }
  }
}
</script>
<style>
</style>
